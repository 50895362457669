<template>
  <div>
    <div
      v-if="open"
      class="fixed top-0 bottom-0 right-0 left-0 background"
      style="z-index: -10; max-width: 1920px; margin: auto"
    ></div>
    <div
      v-click-outside="() => onClickOutsideDropdown()"
      class="relative"
      @mouseenter="openOnHover ? toggleDropdown(true) : null"
      @mouseleave="openOnHover ? toggleDropdown(false) : null"
    >
      <div @click="onClickDropdown()">
        <slot></slot>
      </div>
      <div v-show="open" :class="dropdownClasses">
        <div
          class="mt-6 bg-white shadow-xl rounded-lg dropdown-max-height flex overflow-hidden"
          :class="[height, width]"
        >
          <div class="relative flex-1 flex">
            <div class="flex-1 overflow-y-auto">
              <slot name="dropdown"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const Alignment = {
  LEFT: 'left',
  EXTRA_LEFT: 'extra-left',
  RIGHT: 'right',
  CENTER: 'center',
  SCREEN_CENTER: 'screen-center',
};

export default {
  name: 'RDropdown',
  props: {
    openOnHover: {
      type: Boolean,
      required: false,
      default: false,
    },
    align: {
      type: String,
      required: false,
      default: 'left',
    },
    height: {
      type: String,
      required: false,
      default: '',
    },
    width: {
      type: String,
      required: false,
      default: 'w-max',
    },
    modelValue: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
  emits: ['update:modelValue'],

  data() {
    return {
      open: this.modelValue || false,
    };
  },
  computed: {
    dropdownClasses() {
      const classes = ['z-40 h-16'];

      if (this.align === Alignment.RIGHT) {
        classes.push('absolute -right-5');
      } else if (this.align === Alignment.LEFT) {
        classes.push('absolute -left-5');
      } else if (this.align === Alignment.EXTRA_LEFT) {
        classes.push('absolute -left-38');
      } else if (this.align === Alignment.CENTER) {
        classes.push('absolute left-1/2 transform -translate-x-1/2');
      } else if (this.align === Alignment.SCREEN_CENTER) {
        classes.push('fixed right-1/2 transform translate-x-1/2');
      }

      return classes;
    },
  },
  watch: {
    modelValue(newValue) {
      this.open = newValue;
    },
    open(newValue) {
      this.$emit('update:modelValue', newValue);
    },
  },
  methods: {
    toggleDropdown(newState) {
      /* If no parameters present, assume that we want to just toggle to other value */
      const state = newState === undefined ? !this.open : newState;

      this.$emit('update:modelValue', state);

      /* If not controlled externally, set new open state */
      if (this.modelValue === undefined) {
        this.open = state;
      }
    },
    onClickDropdown() {
      if (!this.openOnHover) {
        this.toggleDropdown();
      }
    },
    onClickOutsideDropdown() {
      if (!this.openOnHover) {
        this.toggleDropdown(false);
      }
    },
  },
};
</script>
<style scoped>
.background {
  background-color: #374151;
  opacity: 0.1;
}

.dropdown-max-height {
  max-height: calc(100vh - 5.5rem);
}
</style>
